import { animate, style, transition, trigger } from '@angular/animations';

export const inOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('1s ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('1s ease-in', style({ opacity: 0 })),
  ]),
]);

export const inOutAnimationFast = trigger('inOutAnimation', [
  transition(':enter', [
    style({ opacity: 0, height: 0 }),
    animate('0.3s ease-in', style('*')),
  ]),
  transition(':leave', [
    style('*'),
    animate('0.3s ease-out', style({ opacity: 0, height: 0 })),
  ]),
]);
